import { render, staticRenderFns } from "./PrintView.vue?vue&type=template&id=79400b80&"
import script from "./PrintView.vue?vue&type=script&lang=js&"
export * from "./PrintView.vue?vue&type=script&lang=js&"
import style0 from "./PrintView.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BContainer} from 'bootstrap-vue'
    installComponents(component, {BContainer})
    

export default component.exports